<template>
  <v-container fluid class="px-6">
    <v-card>

      <v-card-title class="px-6 card-header white--text">
          <v-icon class="mr-3 white--text">mdi-account-eye</v-icon> <h3>User Log List</h3>
      </v-card-title>

      <v-row class="px-8 pt-4">
        <v-col cols="3">
          <v-menu
            v-model="fmenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fdate"
                label="From Date"
                prepend-icon="mdi-calendar-start"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fdate"
              @input="fmenu = false"
              @change="getAllStationLog()"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="3">
          <v-menu
            v-model="tmenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tdate"
                label="To Date"
                prepend-icon="mdi-calendar-end"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="tdate"
              @input="tmenu = false"
              @change="getAllStationLog()"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="2"> </v-col>

        

        <v-col cols="1">
          <v-btn
            color="excel"
            elevation="2"
            @click="exportReport('excel')"
            :disabled="body_list == null"
          >
            <v-icon color="white" center dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="3" align="right">
          <v-text-field
            v-model="searchDt"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined
            hide-details
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        :headers="thead_list"
        :items="body_list"
        :search="searchDt"
        :loading="loadingDt"
        :items-per-page="5"
        class="elevation-1 px-6 headerDtSarawak"
      >
        <template v-slot:item.dataValidationStatus>
          <v-chip color="green" dark>
            Finish Processing
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <template>
            <v-icon small class="mr-2 blue--text" @click="formRequest(item)"
              >mdi-pencil</v-icon
            >
          </template>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import appMixins from "@/mixins/globalMixins";

import Papa from "papaparse";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// -- import something here --

export default {
  data: () => ({

    

    fmenu: false,
    tmenu: false,
    // tdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000) .toISOString() .substr(0, 10),
    fdate: new Date().getFullYear()+"-01-01",
    tdate: new Date().getFullYear()+"-12-31",
    
    loadingDt: false,
    searchDt: "",
    thead_list: [
      { text: "Station ID", value: "stationid"  },
      { text: "Station Activity", value: "hostip" },
      // { text: "IP", value: "idUser" },
      { text: "PIC", value: "pic" },
      { text: "Updated Date & Time", value: "datetime" },
    ],

    body_list: [],

  }),

  mounted() {

    this.getAllStationLog();
  },

  methods: {
    getAllStationLog() {

      // this.loadingDt = true;

      // // const d = new Date();
      // // let currentYear = d.getFullYear();
      // // let prevYear = currentYear - 1;

      // axios
      //   .get(appMixins.data().globalUrl + "mqm2/miqims/log?startdt="+this.fdate+"T00:00:00&enddt="+this.tdate+"T23:59:59", {
      //     headers: {
      //       Authorization: "Bearer " + this.$store.getters.user.token,
      //     },
      //   })
      //   .then((response) => {
      //     let data = response.data;
      //     console.log(data);

      //     this.body_list = data

      //     this.loadingDt = false;
      //   })
      //   .catch((error) => {
      //     console.log(error.response);
      //     this.loadingDt = false;
      //   });
    },

    formRequest(item) {
      this.formApplication = true;
      console.log(this.formApplication, item);
      this.detailForm = item;
    },

    // Export Table into PDF/Excel
    exportReport() {
      
      let objKeys = Object.keys(this.body_list[0])
      for(let i in this.thead_list){
        for(let x in objKeys){
          for(let y in this.body_list){
            if(objKeys[x] == this.thead_list[i].value){
              this.body_list[y][this.thead_list[i].text] = this.body_list[y][objKeys[x]];
            }
          }
        }
      }

      let keyValueHeader = [];
      for(let i in this.thead_list){
        keyValueHeader.push(this.thead_list[i].text)
      }

      let dataPrint = this.body_list;

      let keys = keyValueHeader,
      resultsss = dataPrint.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));
      let objExport = resultsss;

      console.log(objExport);

      let blob = new Blob([Papa.unparse(objExport)], { type: 'text/csv;charset=utf-8;' });

      let link = document.createElement("a");

      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", 'List of Station Log.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    },
  },
};
</script>

<style lang="scss">
@import "~scss/main";

.background {
  background-color: whitesmoke;
  height: 100%;
  width: 100%;
  top: 0;
  background-size: cover;
  top: 0;
  right: 0;
}

.form {
  // width: 500px;
}
</style>
